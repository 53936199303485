import AccentProvider from '@/lib/hooks/accentContext/AccentContext';
import { EventContextProvider } from '@/lib/hooks/analytics/useEventContext';
import OverlayProvider from '@/lib/hooks/overlayContext/OverlayContext';
import { PersistentBannerProvider } from '@/lib/hooks/persistentBanner/PersistentBanner';
import { ShelterLocationProvider } from '@/lib/hooks/shelterDashboard/ShelterLocation';
import { ShelterReferralProvider } from '@/lib/hooks/shelterReferral/ShelterReferral';
import { ToastProvider } from '@/lib/hooks/toasts/Toast';
import { UserContextProvider } from '@/lib/hooks/userContext/UserContext';
import getBasePath from '@/lib/utils/getBasePath/getBasePath';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ReactNode } from 'react';

/**
 * IProviderLayout
 *
 * @interface IProviderLayout
 */
export interface IProviderLayout {
  /**
   * Content of the Layout
   *
   * @memberof IProviderLayout
   * @member {ReactNode} children
   */
  children: ReactNode;
}

/**
 * Provider Layout Used to provide all required top level providers to the
 * application
 *
 * @param {IProviderLayout} props - The props for the ProviderLayout component.
 * @returns {React.FC<IProviderLayout>} ProviderLayout Component
 */
const ProviderLayout: React.FC<IProviderLayout> = ({
  children,
}: IProviderLayout) => {
  return (
    <UserProvider profileUrl={`${getBasePath()}/api/auth/me`}>
      <UserContextProvider>
        <PersistentBannerProvider>
          <EventContextProvider>
            <ToastProvider>
              <OverlayProvider defaultOverlay={false}>
                <AccentProvider>
                  <ShelterReferralProvider>
                    <ShelterLocationProvider>
                      {children}
                    </ShelterLocationProvider>
                  </ShelterReferralProvider>
                </AccentProvider>
              </OverlayProvider>
            </ToastProvider>
          </EventContextProvider>
        </PersistentBannerProvider>
      </UserContextProvider>
    </UserProvider>
  );
};

export default ProviderLayout;
